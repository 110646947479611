import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"
import LastFourWpOpinion from "../components/lastFourWpOpinion"

const Opinion = ({ pageContext }) => {
  const { title, content, acfOpinions, data } = pageContext
  const fullSchema = JSON.parse(data.seo.schema.raw)

  return (
    <Layout light>
      <SEO
        title={
          data.seo.metaDesc ? data.seo.metaDesc : `Opinie Klientów - ${title}`
        }
        description={data.seo.metaDesc}
      />

      <GlobalTop title={title} opinion light />

      <section className="opinion-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center">
              {acfOpinions.opinionScreenshot ? (
                <div className="opinion-content__screen">
                  <img
                    className="img-fluid"
                    src={acfOpinions.opinionScreenshot.sourceUrl}
                    alt={`Omeda-Med ${title}`}
                  />
                </div>
              ) : (
                "Brak..."
              )}
            </div>
            <div className="col-md-6">
              <strong>Opinia:</strong>
              <img
                className="img-fluid"
                src={require(`../assets/images/logo-${acfOpinions.opinionSource}.svg`)}
                alt={`Omeda-Med ${title}`}
              />
              <br />
              <br />
              <strong>Ocena:</strong>
              <div className="opinions__tile-stars">
                {acfOpinions.opinionStars > 0 ? (
                  <span></span>
                ) : (
                  <span className="empty"></span>
                )}
                {acfOpinions.opinionStars > 1 ? (
                  <span></span>
                ) : (
                  <span className="empty"></span>
                )}
                {acfOpinions.opinionStars > 2 ? (
                  <span></span>
                ) : (
                  <span className="empty"></span>
                )}
                {acfOpinions.opinionStars > 3 ? (
                  <span></span>
                ) : (
                  <span className="empty"></span>
                )}
                {acfOpinions.opinionStars > 4 ? (
                  <span></span>
                ) : (
                  <span className="empty"></span>
                )}
              </div>
              <h4>{title}</h4>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </section>

      <section className="opinion-more">
        <div className="container">
          <h2>Więcej opinii</h2>
          <div>
            <LastFourWpOpinion />
          </div>
          <div className="opinion-more__action">
            <Link
              to="/opinie-klientow/"
              className="btn-custom btn-custom--purple"
            >
              Zobacz więcej
            </Link>
          </div>
        </div>
      </section>
      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export default Opinion
