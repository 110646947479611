import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const LastFourWpOpinion = ({ allWpOpinion }) => {
  return (
    <div className="row">
      {allWpOpinion.nodes.map((node, index) => (
        <div className="col-md-6" key={index}>
          <div className="opinions__tile">
            <h4>
              {node.title}{" "}
              <img
                className="img-fluid"
                src={require(`../assets/images/logo-${node.acfOpinions.opinionSource}.svg`)}
                alt={`Omeda-Med ${node.title}`}
              />
            </h4>
            <div className="opinions__tile-stars">
              {node.acfOpinions.opinionStars > 0 ? (
                <span></span>
              ) : (
                <span className="empty"></span>
              )}
              {node.acfOpinions.opinionStars > 1 ? (
                <span></span>
              ) : (
                <span className="empty"></span>
              )}
              {node.acfOpinions.opinionStars > 2 ? (
                <span></span>
              ) : (
                <span className="empty"></span>
              )}
              {node.acfOpinions.opinionStars > 3 ? (
                <span></span>
              ) : (
                <span className="empty"></span>
              )}
              {node.acfOpinions.opinionStars > 4 ? (
                <span></span>
              ) : (
                <span className="empty"></span>
              )}
            </div>
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: node.content.substring(0, 240),
                }}
              />
              {node.content.length > 240 && "... "}
              <Link to={`/opinie-klientow${node.uri}`}>Więcej</Link>
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allWpOpinion: allWpOpinion(limit: 4) {
          nodes {
            uri
            id
            slug
            title
            content
            excerpt
            acfOpinions {
              opinionSource
              opinionStars
              opinionScreenshot {
                sourceUrl
              }
            }
          }
        }
      }
    `}
    render={data => <LastFourWpOpinion {...data} />}
  />
)
